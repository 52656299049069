<template>
  <div>
    <Header> </Header>
    <div class="homepage-hero">
      <h1 class="welcome-user">{{ welcome_message_title }}</h1>
      <!-- <h1 class="dashboard-header">{{ lang_stanza.dashboard }}</h1> -->
      <p class="dashboard-blurb" v-html="lang_stanza.change_preferences"></p>
      <div class="header-icons">
        <div>
          <img
            src="../assets/TRT_SITE_Icon_Tools_Smartphone_Hand_Blue.png"
            alt="smartphone icon"
          />Review the steps in your plans below
        </div>
        <div>
          <img
            src="../assets/TRT_SITE_Icon_Reward_Check_Blue.png"
            alt="reward check icon"
          />Check off activities to stay on track
        </div>
        <div>
          <img
            src="../assets/TRT_SITE_Icon_Reward_Ribbon_Blue.png"
            alt="reward ribbon icon"
          />See your teen reach their goals
        </div>
      </div>
    </div>
    <div class="action-plans-banner">Your Action Plans</div>
    <div id="home-content" class="home-container">
      <div>
        <div class="card-row">
          <div class="card-col">
            <div
              class="card"
              v-for="gp in gp_filtered_list"
              :key="gp.grade_pathway_id"
            >
              <router-link
                variant="link"
                :to="`/${lang_info.lang_code}/action-plan/${gp.grade_key}/${gp.pathway_key}`"
              >
                <!--
                :class="switchClass(user_grade_pathway_list.grade_key)">{{ user_grade_pathway_list.grade_title }}</span>
                -->
                <p class="card-text">
                  <span :class="`grade_key_${gp.grade_key}`">{{
                    gp.grade_title
                  }}</span>
                </p>
                <img
                  :src="switchImage(gp.grade_key, gp.pathway_key)"
                  class="card-img-top"
                  alt="..."
                />
                <h5 class="card-title">{{ gp.pathway_title }}</h5>
                <div :class="`underline_${gp.grade_key}`"></div>
                <div class="card-body">
                  <p class="card-text">
                    {{ gp.grade_pathway_title }}
                  </p>
                  <img
                    src="../assets/Chevron-Plain-Right.svg"
                    class="orange-arrow"
                    alt="orange arrow"
                  />
                </div>
              </router-link>
            </div>
          </div>
          <div class="acct-settings-text">
            Want to change your plans? You can update what you see on your
            homepage in your
            <a style="color: #047ac3 !important" href="/en/profile">
              account settings</a
            >.
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { grades } from "../_data/grade-data.json";
import { router, sessionStorage } from "../_helpers";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
import GTM from "../_helpers/gtm";

export default {
  title: "Your personalized dashboard | Tomorrow Ready Texas",
  name: "Home",
  data() {
    return {
      first_name: "",
      welcome_message_title: "",
      gp_filtered_list: [],
      lang_stanza_key: "home",
      lang_stanza: {},
      lang_info: {},
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    if (this.lang_info.lang_code === "en") {
      document.documentElement.setAttribute("lang", "en");
    } else {
      document.documentElement.setAttribute("lang", "es");
    }

    let oidc_id;
    let sessionUserProfile = sessionStorage.decodeUserSession();
    if (!sessionUserProfile || !sessionUserProfile.sub) {
      await router.push("`/${lang_info.lang_code}/landing`");
      return;
    }
    oidc_id = sessionUserProfile.sub;

    await this.getUserProfileByOidc(oidc_id);

    this.welcome_message_title = `${this.lang_stanza.welcome} ${this.userProfile.first_name}!`;

    await this.getUserPreferences({
      oidc_id: oidc_id,
      lang_code: this.lang_info.lang_code,
    });

    // if (!this.user_grade_pathway_list || this.user_grade_pathway_list.length < 1) {
    await this.getUserGradePathwayList({
      oidc_id: oidc_id,
      lang_code: this.lang_info.lang_code,
    });
    if (!this.user_grade_pathway_list) {
      await this.getUserGradePathwayList({
        oidc_id: oidc_id,
        lang_code: this.lang_info.lang_code,
      });
    }
    this.gp_filtered_list = [];
    this.user_grade_pathway_list.forEach((grade) => {
      grade.pathway_list.forEach((pathway) => {
        if (pathway.is_pathway_selected) {
          let gp_item = {};
          gp_item.grade_key = grade.grade_key;
          gp_item.grade_title = grade.grade_title;
          gp_item.grade_text_suffix = grade.grade_text_suffix;
          gp_item.pathway_key = pathway.pathway_key;
          gp_item.pathway_title = pathway.pathway_title;
          gp_item.grade_text = grade.grade_text;
          gp_item.grade_pathway_id = pathway.grade_pathway_id;
          gp_item.grade_pathway_title = pathway.grade_pathway_title;
          this.gp_filtered_list.push(gp_item);
        }
      });
    });

    //  if (!this.user_grade_pathway_list || this.user_grade_pathway_list.length < 1) {
    //  }

    document.body.style.backgroundColor = "#F5F7FA";
  },
  methods: {
    ...mapActions("preferences", [
      "getUserPreferences",
      "getUserGradePathwayList",
    ]),
    ...mapActions("account", ["getUserProfileByOidc"]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    //Imports Grades from local store and filters preferences by grade
    organizeUserGradePathwayList() {
      let userGradePathwayList = [];
      grades.forEach((grade) => {
        let user_grade_pathway_list = this.user_grade_pathway_list.filter(
          (x) => x.grade_key === grade.grade_key
        );
        if (user_grade_pathway_list.length > 0) {
          user_grade_pathway_list.forEach((item) => {
            item.pathways.forEach((pathway) => {
              let new_item = {};
              Object.assign(new_item, item);
              delete new_item.pathways;
              new_item["pathway_id"] = pathway.pathway_id;
              new_item["pathway_key"] = pathway.pathway_key;
              new_item["pathway_title"] = pathway.pathway_title;
              new_item["grade_pathway_id"] = pathway.grade_pathway_id;
              new_item["grade_pathway_title"] = pathway.grade_pathway_title;
              userGradePathwayList.push(new_item);
            });
          });
        }
      });
      return userGradePathwayList;
    },
    //Looks for Pathway title and renders image accordingly
    switchImage(grade_key, pathway_key) {
      let userAgentString = navigator.userAgent;
      let chromeAgent = userAgentString.indexOf("Chrome") > -1;
      let safariAgent = userAgentString.indexOf("Safari") > -1;

      if (chromeAgent && safariAgent) {
        return `${require("../assets/" +
          grade_key +
          "th_" +
          pathway_key +
          ".webp")}`;
      } else {
        return `${require("../assets/" +
          grade_key +
          "_" +
          pathway_key +
          ".png")}`;
      }

      // return `${require("../assets/" +
      //   grade_key +
      //   "th_" +
      //   pathway_key +
      //   ".webp")}`;
    },
  },
  computed: {
    ...mapState("preferences", ["user_grade_pathway_list"]),
    ...mapState("account", ["userProfile"]),
    userState() {
      this.refresh;
      let user = {};
      user = { profile: this.userProfile, status: this.status };
      return user;
    },
    /*
    gpList() {
      this.refresh;
      let sessionUserProfile = sessionStorage.decodeUserSession();
      this.getUserGradePathwayList(sessionUserProfile.sub);
      // if (!this.user_grade_pathway_list || this.user_grade_pathway_list.length < 1) {
      //   this.getUserGradePathwayList(sessionUserProfile.sub);
      // }
      let filtered_gp_list = this.user_grade_pathway_list.filter(grade_pathway => {
        let new_pathway_list = grade_pathway.pathway_list.filter(pathway => {

          if (pathway.is_pathway_selected) {
              return pathway;
          }
        });
        if (new_pathway_list.length > 0) {
          grade_pathway.pathway_list = new_pathway_list;
          return grade_pathway;
        }
      });
      return filtered_gp_list
    },
     */
  },
};
</script>

<style lang="scss" scoped>
.homepage-hero {
  background-image: url("../assets/PersonalizedHero.jpg");
  height: 487px;
  position: relative;
  // top: -52px;
  text-align: left;
  padding-left: 80px;
  padding-top: 135px;
  background-size: cover;
  background-position-x: right;
}

.header-icons {
  display: grid;
  grid-template-columns: 160px 160px 160px;
  text-align: center;
  gap: 32px;
}

.header-icons div {
  display: grid;
  grid-template-rows: 80px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.header-icons img {
  margin: auto;
}

.action-plans-banner {
  height: 52px;
  background-color: #036cae;
  position: relative;
  bottom: 52px;
  text-align: left;
  color: #ffffff;
  padding: 16px 0px 16px 48px;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  margin-bottom: 12px;
}

.dashboard-header {
  color: #030303;
  font-family: Lato;
  font-size: 64px;
  font-weight: bold;
  white-space: nowrap;
}

.column {
  width: 33.3%;
}

.card-row {
  display: grid;
}

.card > a:hover {
  text-decoration: none;
}

.card:hover {
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
}

.card-img-top {
  width: 325.36px;
  height: 222px;
  padding-left: 32px;
  margin-top: 16px;
}

.card-title {
  text-align: left;
  margin-left: 32px;
  margin-top: 16px;
  margin-bottom: 8px !important;
  color: #030303;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.underline_8 {
  width: 296px;
  height: 4px;
  margin-left: 32px;
  background: linear-gradient(
    90deg,
    #f25f29 0%,
    rgba(242, 95, 41, 0.5) 44.64%,
    rgba(242, 95, 41, 0.25) 100%
  );
}

.underline_9 {
  width: 296px;
  height: 4px;
  margin-left: 32px;
  background: linear-gradient(
    90deg,
    #f2ac29 0%,
    rgba(242, 172, 41, 0.5) 47.62%,
    rgba(242, 172, 41, 0.25) 100%
  );
}

.underline_10 {
  width: 296px;
  height: 4px;
  margin-left: 32px;
  background: linear-gradient(
    90deg,
    #03a66a 0%,
    rgba(3, 166, 106, 0.5) 49.38%,
    rgba(3, 166, 106, 0.25) 100%
  );
}

.underline_11 {
  width: 296px;
  height: 4px;
  margin-left: 32px;
  background: linear-gradient(
    90deg,
    #0487d9 0%,
    rgba(4, 135, 217, 0.5) 46.88%,
    rgba(4, 135, 217, 0.25) 100%
  );
}

.underline_12 {
  width: 296px;
  height: 4px;
  margin-left: 32px;
  background: linear-gradient(
    90deg,
    rgba(140, 50, 116, 0.25) 0%,
    #557eba 0%,
    #8c3274 0%,
    rgba(140, 50, 116, 0.5) 50.58%,
    rgba(140, 50, 116, 0.25) 102.36%
  );
}

.card-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  width: 296px;
}

.card-body {
  margin-left: 12px;
  padding-top: 8px;
}

p span {
  margin-bottom: 0px;
  border-radius: 16px 0px;
  padding: 5px 10px;
  color: #000000;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.grade_key_8 {
  background-color: #f57f54;
}

.grade_key_9 {
  background-color: #f6c569;
}

.grade_key_10 {
  background-color: #35b888;
}

.grade_key_11 {
  background-color: #369fe1;
}

.grade_key_12 {
  background-color: #af709e;
}

.card p {
  margin-bottom: 0px;
  margin-top: 0px !important;
  color: #666666;
}

.orange-arrow {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 24px;
  bottom: 16px;
}

.acct-settings-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-top: 40px;
  margin-bottom: 64px;
  display: flex;
  padding-left: 15px;
  text-align: left;
}

.acct-settings-text a {
  margin-left: 3px;
  display: contents;
}

// @media (min-width: 992px) and (max-width: 1200px) {
//   .homepage-hero {
//     height: 383px !important;
//   }
// }

@media (min-width: 900px) {
  .home-container {
    max-width: 1300px !important;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    padding: 0 30px;
  }
  .homepage-hero {
    background-image: url("../assets/PersonalizedHero.jpg");
    height: 487px;
    position: relative;
    // top: -52px;
    text-align: left;
    padding-left: 80px;
    padding-top: 135px;
    background-size: cover;
    background-position-x: right;
  }

  .welcome-user {
    color: #030303;
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 20px;
    margin-top: -26px;
    margin-bottom: 32px;
  }

  .dashboard-blurb {
    color: #000000;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-top: 4px;
    margin-bottom: 48px;
    width: 533px;
  }

  .card-col {
    display: grid;
    gap: 64px 32px;
    place-items: center;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    flex-wrap: wrap;
  }

  .card {
    width: 360px;
    height: 440px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    border: none;
    cursor: pointer;
  }
}
// @media (min-width: 900px) and (max-width: 1125px) {
//  .acct-settings-text {
//    display: inline-flex;
//    padding-left: 20%;
//  }
// }

// @media (min-width: 1126px) and (max-width: 1202px) {
//   .acct-settings-text {
//     padding-left: 15%;
//   }
// }

@media (min-width: 900px) and (max-width: 1203px) {
  .card-col > .card:nth-child(odd) {
    justify-self: end !important;
  }

  .card-col > .card:nth-child(even) {
    justify-self: start !important;
  }
}

// @media (min-width: 500px) and (max-width: 900px) {
//   .acct-settings-text {
//     padding-left: 10%;
//     max-width: 700px;
//   }
// }

@media (min-width: 500px) and (max-width: 800px) {
  .acct-settings-text {
    max-width: 500px;
  }
}

@media (min-width: 376px) and (max-width: 900px) {
  //tablet
  //   .home-container {
  //     max-width: 1240px !important;
  //     margin-left: auto;
  //     margin-right: auto;
  //     text-align: left;
  //   }

  .homepage-hero {
    //background-image: url("../assets/PersonalizedHero.jpg");
    height: 487px;
    position: relative;
    // top: -52px;
    text-align: left;
    padding-left: 80px;
    padding-top: 135px;
    background-size: cover;
    background-position-x: right;
  }
  .welcome-user {
    color: #030303;
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 20px;
    margin-top: -26px;
    margin-bottom: 32px;
  }
  .dashboard-blurb {
    color: #000000;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-top: 4px;
    margin-bottom: 48px;
    width: 533px;
  }
  .card-col {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    flex-wrap: wrap;
  }

  .card {
    text-align: left;
    width: 360px;
    height: 448px;
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
    border: none;
    margin-bottom: 40px;
    cursor: pointer;
    text-align: left;
  }
}

@media (min-width: 500px) and (max-width: 685px) {
  .header-icons {
    display: grid;
    grid-template-columns: 125px 125px 125px;
    text-align: center;
    gap: 20px;
  }

  .dashboard-blurb {
    width: 350px;
  }
}

// @media (max-width: 500px) {
//   .header-icons {
//     display: grid;
//     grid-template-columns: 98px 98px 98px;
//     text-align: center;
//     gap: 20px;
//   }

//   .homepage-hero {
//     background-image: none !important;
//     background-color: #fafafa;
//     padding-left: 16px;
//     padding-top: 24px;
//     top: 0;
//     height: 363px;
//   }
// }

@media (min-width: 360px) and (max-width: 500px) {
  //mobile

  .homepage-hero {
    background-image: none !important;
    background-color: #fafafa;
    padding-left: 16px;
    padding-top: 24px;
    top: 0px;
    height: 363px;
  }

  .header-icons {
    display: grid;
    grid-template-columns: 98px 98px 98px;
    text-align: center;
    gap: 20px;
  }

  .header-icons div {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .header-icons img {
    width: 30px;
  }

  .welcome-user {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-left: 0px;
    margin-bottom: 16px;
    padding-top: 24px;
  }

  .action-plans-banner {
    height: 68px;
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    padding-left: 16px;
    margin-bottom: -24px;
  }

  .home-container {
    max-width: 1240px !important;
    margin-left: auto;
    margin-right: auto;
    text-align: left !important;
  }

  .dashboard-blurb {
    color: #000000;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 16px;
    max-width: 360px;
    margin-left: 0px;
    margin-right: 7px;
  }

  .card-col {
    display: grid;
    gap: 24px;
    place-items: center;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    flex-wrap: wrap;
  }

  .card {
    max-width: 360px;
    height: 448px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    border: none;
    margin-bottom: 0px;
    cursor: pointer;
  }

  .orange-arrow {
    height: 24px;
    width: 24px;
    right: 11px;
    bottom: 11px;
  }

  .acct-settings-text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin: 24px 8px -20px 8px;
    text-align: left;
  }
}

@media (max-width: 375px) {
  .card-img-top {
    padding-left: 22px;
  }

  .card-title {
    margin-left: 22px;
  }

  .underline_8,
  .underline_9,
  .underline_10,
  .underline_11,
  .underline_12 {
    margin-left: 22px;
  }

  .card-body {
    margin-left: 2px;
    margin-right: 10px;
  }
}
</style>
